<template>
    <div>
        <div class="full-width category-container m-t-36">
            <div class="tac container neue neueSettings">
                <Breadcrumbs :path="path" />
            </div>
        </div>
        <div class="full-width p10">
            <div class="tac container flexColumn">
                <h3 class="neue title neueSettings">Progetto QUID</h3>
                <p class="neue neueSettings"><b>Abbigliamento</b></p>

                <center class="only-mobi">
                  <img class="quidLogo" :src="quidLogo.url" alt="" srcset="" style="margin-bottom: 0">
                </center>
            </div>
        </div>
        <div class="full-width m-t-36 p10">
            <div id="infoTable" class="container neue neueSettings">
                <div class="container70">
                    <div class="row ">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Paese</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Italia</p>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Prodotti</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">All’interno della Cooperative Collection di On Earth, Progetto Quid propone una selezione di abbigliamento etico. La produzione impiega tessuti di fine serie, stock invenduti o donati da aziende tessili italiane e da brand del mondo della moda internazionale.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Valore protetto / impegno</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Riscatto sociale, soprattutto di donne in condizione di svantaggio o di fragilità</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Con che paesi lavora</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Quid lavora con realtà italiane e intende sperimentare il <b>reinserimento lavorativo di donne in difficoltà</b> attraverso il loro impiego in attività produttive che rispondono alle logiche del mercato e che allo stesso tempo stimolano una partecipazione attiva alla bellezza e alla creatività.</p>
                        </div>
                    </div>
                </div>
                <div class="container20">
                    <img class="quidLogo no-mobi" :src="quidLogo.url" alt="" srcset="">
                    <img class="miniMap" :src="miniMap.url" alt="" srcset="">
                    <a class="neue neueSettings" href="https://shop.progettoquid.com" target="_blank">shop.progettoquid.com</a>
                </div>
            </div>
        </div>
        <div id="slider" class="mb-6r">
            <VueSlickCarousel v-bind="settings">
                <img src="@/assets/quid/MAC13379.jpg" alt="">
                <img src="@/assets/quid/MAC13499.jpg" alt="">
                <img src="@/assets/quid/MAC18177.jpg" alt="">
                <img src="@/assets/quid/MAC18752.jpg" alt="">
                <img src="@/assets/quid/MC1_1308.jpg" alt="">
                <img src="@/assets/quid/MC1_1382.jpg" alt="">
                <img src="@/assets/quid/MC1_3191.jpg" alt="">
            </VueSlickCarousel>
        </div>
        <div class="full-width mb-4r p10">
            <div class="container flexColumn">
                <div class="row flexColumn">
                    <h3 class="neue title neueSettings">Descrizione</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;"><b>Progetto Quid è il brand di moda etica e sostenibile dell’impresa sociale Quid</b>, fondata nel 2012 e riconosciuta a livello europeo come innovatore sociale. Si tratta di un laboratorio sartoriale con sede in provincia di Verona ed <b>occupa persone, prevalentemente donne, che si trovano in una condizione di svantaggio o di fragilità, creando per loro opportunità di impiego stabile, formazione e crescita lavorativa.</b> Dal 2013 ad oggi sono state impiegate più di 150 persone provenienti da 17 Paesi: di queste, il 70% appartiene a categorie protette o svantaggiate. La produzione impiega in prevalenza <b>eccedenze di tessuti</b> provenienti sia da esperti tessutai italiani sia da noti brand internazionali della moda. Questa peculiarità produttiva evita di destinare al macero tessuti pregiati, consentendo un <b>ridotto impatto ambientale</b>.</p>
                </div>   
                <div class="row flexColumn m-t-36">
                    <h3 class="neue title neueSettings">Curiosità</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">L’idea imprenditoriale nata dal duplice obiettivo di creare inclusione sociale e lavorativa è valsa al progetto il riconoscimento di prestigiosi <b>premi anche a livello internazionale</b>: tra questi, <em>European Social Innovation Competition</em> (2014), <em>European Civil Society Prize</em> (2017), <em>Lighthouse Activity nella categoria “Women for Results” di Momentum for Change</em> assegnato dalle Nazioni Unite – UNFCCC (2017) e il prestigioso “<b>CNMI Responsible Disruption Award</b>” assegnato durante i <b>Green Carpet Fashion Awards 2020</b>, la più importante iniziativa volta a premiare l'ecosostenibilità nel mondo della moda.</p>
                </div> 
            </div>
        </div>
        <!--<ProductSlider />-->
    </div>
</template>
<script>
    import Breadcrumbs from '@/components/Breadcrumbs'
    //import ProductSlider from '@/components/ProductSlider'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    const quidLogo = require('../assets/quid/quid.png')

    export default {
        name : 'Quid',
        components : {
            Breadcrumbs,
            //ProductSlider,
            VueSlickCarousel
        },

        data() {
            return {
                path: [
                    { label: 'Home', path: '/' },
                    { label: 'Brand', path: '/brand' },
                    { label: 'Progetto Quid', path: '/quid' }
                ],
                miniMap : {
                    url : 'https://www.altromercato.it/wp-content/uploads/2021/09/Bosnia-ed-Erzegovina.jpg'
                },
                quidLogo : {
                    url : quidLogo
                },
                settings : {
                    "arrows": true,
                    "dots": false,
                    "infinite": true,
                    "slidesToShow": 4,
                    "slidesToScroll": 1,
                    "speed": 500,
                    "cssEase": "linear",
                    "responsive": [
                        {
                            "breakpoint": 668,
                            "settings": {
                                "speed": 500,
                                "slidesToShow": 2,
                                "slidesToScroll": 2,
                                "infinite": true,
                                "dots": false
                            }
                        },
                    ]
                }
            }
        }
    }

</script>
<style >
    #slider .slick-next {
        right: 67px;
    }
    #slider .slick-prev {
        left: 67px;
        z-index: 1;
    }
    .mb-4r{
        margin-bottom: 4rem;
    }
    .mb-6r{
        margin-bottom: 6rem;
    }
    .miniMap{
        max-width: 100%;
        border: 0.5px solid rgba(128, 128, 128, 0.2);
        padding: 10px;
        margin-bottom: 2rem;
    }
    .quidLogo{
        max-width: 50%;
        margin-bottom: 2rem;
    }
    .title{
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin: 0;
    }
    .container70{
        max-width: 75%;
    }
    .container20{
        max-width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateY(-120px);
    }
    .neueSetBold{
        font-weight: 600;
    }
    .neueSettings{
        letter-spacing: 0.5px;
        line-height: 1.5em;
    }
    .flexColumn{
        flex-direction: column;
    }
    .col-25{
        width: 30%;
        max-width: 30%;
    }
    .col-50{
        width: 50%;
        max-width: 50%;
    }
    .col-60{
        width: 60%;
        max-width: 60%;    
    }
    .col-75{
        width: 75%;
        max-width: 75%;
    }
    @media(max-width: 668px){
        #infoTable{
            flex-direction: column;
        }
        #infoTable .container70{
            max-width: 100%;
        }
        #infoTable .container20{
            transform: unset;
            max-width: 100%;
        }
        #infoTable .quidLogo{
            max-width: 20%;
            margin-top: 2rem;
        }
        #infoTable a{
            margin-bottom: 4rem;
        }
        .col_sm-100{
            max-width: 100%;
            width: 100%;
        }
        .p10{
            padding: 10px;
        }
        .tac{
            text-align: center;
        }
    }
</style>
